@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply min-h-screen bg-indigo-100;
}

.pac-container {
  @apply rounded-lg mt-1;
}

#__jorel {
  transform: translate(0, -52px) scale(0.7) !important;
}

#__jorel.minimized {
  display: none;
}